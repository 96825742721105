/* global dk */
import {Package} from "./package";
import current_tags from './current-tags.json';



function humanize_int(v) {
    if (v < 1000) return v;
    const res = [];
    while (v) {
        res.push(v % 1000);
        v = Math.floor(v / 1000);
    }
    return res.reverse().join('&thinsp;');
}

// from dktools/dktools/dktools/requirements.py
const ordering = `

    dkfileutils yamldirs dkpdf ttcal dkjason dkpkg dkidentifiers dkdot
    dknorway dkddog
    dk
    dkmodelmeta
    siteowners dkrunners dkredis dkpw dkglapi
    djnetaxept dktemplate dk-template

    dkphone ecdl dktabular dkrule2 dkmath dkmodelfields dkjs dkdj nag
    dkmarkup  almanac nff passwordlib dkrepo dktasklib dkphoto dksys
    qmlib norsk commandq toolcall dksync dkdocs dkcode dkbuild
    dkresultgrid dkorm olddatagrid dktestpackage orms dkdjango dktools
    dkcoremodels dktest dkforms dkbrowsercheck dklogin dksignup finautmapping
    digitaltesten dkface tt3 qm stdemail dkmisc dktestcenter dkrapport dkvox
    dkprofile dkaddress dkspm dkdash dkruntime dkfakturagrunnlag cmspagetools
    dkcompany dkebskrapelodd dkstructdoc dkcards dksms ntcms datakortetno
    finautuser dkajax dkstore postliste hyper fautuserstatus dkuser
    finautcompany finautstructdoc fotballdommer dkshop dkeborger dkmaint
    finautlogin dknewsletter ftsettings finautrules dksphinxdoc finautfaktura
    toolcall_server finautdiploma diploma curriculum dkeureka dkexam finautresults sparq respekt
    proctorexam tctrdash dkcal examradar solarium innkjop bfpregistration ndb
    finautexam finlib finautprofile dksite personforsikring gos finautapp
    dossier kreditt afr bfp loginapp prisliste fareports

`.split(/\s+/g).filter(v => !!v);


export class Packages extends dk.Widget {
    constructor(...args) {
        super({
            buildall_buttonid: undefined,
            builderr_buttonid: undefined,
            buildchanged_buttonid: undefined,
            buildfast_buttonid: undefined,

            _found_packages: false,
            _pending_buildall: false,
            _current_sort: 'name',
            _current_sort_direction: 1,  // ascending'ish

            packages: {},
            package_list: [],
            structure: {
                css: {
                    // flex: 1,
                    // display: 'flex',
                    // flexDirection: 'column',
                },
                cmdline: {
                    css: {
                        display: 'flex',
                        justifyContent: 'space-between',
                    },
                    sortbtns: {
                        css: {
                            height: 22
                        },
                        classes: ['btn-group'],
                        sort_heading: {
                            template: 'span',
                            css: {marginRight: '1em'},
                            text: 'sort:'
                        },
                        namesort: {
                            template: 'button',
                            text: 'name',
                            classes: ['btn btn-default btn-xs active']
                        },

                        pipelinesort: {
                            template: 'button',
                            text: 'pipeline',
                            classes: ['btn btn-default btn-xs']
                        },
                        dependasort: {
                            template: 'button',
                            text: 'deps',
                            classes: ['btn btn-default btn-xs']
                        },
                        covsort: {
                            template: 'button',
                            text: 'coverage',
                            classes: ['btn btn-default btn-xs']
                        },
                        locsort: {
                            template: 'button',
                            text: 'loc',
                            classes: ['btn btn-default btn-xs']
                        },
                        timesort: {
                            template: 'button',
                            text: 'duration',
                            classes: ['btn btn-default btn-xs']
                        },
                        valuesort: {
                            template: 'button',
                            text: 'value',
                            classes: ['btn btn-default btn-xs']
                        },
                        // lintsort: {
                        //     template: 'button',
                        //     text: 'lintscore',
                        //     classes: ['btn btn-default btn-xs']
                        // },
                        // lcsort: {
                        //     template: 'button',
                        //     text: 'lines',
                        //     classes: ['btn btn-default btn-xs']
                        // },
                        // changesort: {
                        //     template: 'button',
                        //     text: 'changed',
                        //     classes: ['btn btn-default btn-xs']
                        // }
                        // errsort35: {
                        //     template: 'button',
                        //     text: 'errors 3.5',
                        //     classes: ['btn btn-default btn-xs']
                        // },
                    },
                    statistics: {
                        css: {
                            display: 'block'
                        },
                        package_count: {
                            css: {marginRight: '1ex'},
                            template: 'span'
                        },

                        // missing_py35: {
                        //     css: {marginRight: '1ex'},
                        //     template: 'span'
                        // },
                        // dj18: {
                        //     css: {marginRight: '1ex'},
                        //     template: 'span'
                        // },
                        // package_locs: {
                        //     css: {marginRight: '1ex'},
                        //     template: 'span'
                        // },
                        // package_passing: {
                        //     css: {marginRight: '1ex'},
                        //     template: 'span'
                        // },
                        // package_failing: {
                        //     css: {marginRight: '1ex'},
                        //     template: 'span'
                        // },
                        // package_failing_18: {
                        //     css: {marginRight: '1ex'},
                        //     template: 'span'
                        // },
                        pipeline_status: {
                            css: {marginRight: '1ex'},
                            template: 'span'
                        },
                        avg_coverage: {
                            css: {marginRight: '1ex'},
                            template: 'span'
                        },
                        avg_score: {
                            template: 'span'
                        },
                        py35: {
                            css: {marginRight: '1ex'},
                            template: 'span'
                        },
                    }
                },
                package_box: {
                    css: {
                        flex: 1,
                        // marginTop: 4,

                        display: 'flex',
                        flexDirection: 'column',
                        flexWrap: 'wrap',
                        alignContent: 'start',
                        // height: 'calc(100vh - 90px)'
                        // height: '100vh'
                    }
                }
            },

        }, ...args);
        this.pipeline_success_count = 0;
        this.pipeline_running_count = 0;
        this.pipeline_fail_count = 0;
        this.locs = 0;
        this.tag_errors = {};
        current_tags.forEach(t => {
            this.tag_errors[t] = 0;
        });
        this.py27_fail_count = 0;
        this.py35_fail_count = 0;

        this.packages_with_test_failures = 0;
        this.packages_without_test_failures = 0;
        this.avg_score = 0;
        this.package_count = 0;
        this.coverage = 0.0;
    }

    add(p) {
        this.packages[p.name] = p;
        this.package_list.push(p);
        dk.on(p, 'data-updated', (obj, data) => {
            this.trigger('data-updated', this, obj, data);
        });
        this.trigger('package-add', p);
    }

    add_packages(statusdata) {
        this.packages = {};
        let covtot = 0.0;
        const categories_set = new Set();

        statusdata.forEach(d => {
            // dk.log("Processing:", d);
	    if (d.name ==='yoda' || d.name === 'yoda5') return;
            try {
                const p = Package.append_to(this.package_box, {
                    data: d
                });
                this.locs += p.loc;
                this.package_count += 1;
                switch (p.pipeline_status) {
                    case 'success':
                        if (p.has_results()) {
                            this.pipeline_success_count += 1;
                            covtot += p.avg_coverage;
                        } else {
                            this.pipeline_fail_count += 1;
                        }
                        break;
                    case 'running':
                        this.pipeline_running_count += 1;
                        break;
                    default:
                        this.pipeline_fail_count += 1;
                        break;
                }
                p.categories.forEach(c => categories_set.add(c));
                this.add(p);
            } catch (e) {
                dk.log("ERROR:", d)
            }
        });
        dk.log("Categories:", categories_set);

        const categories = {};
        for (let c of categories_set) {
            categories[c] = {};
        }
        // categories_set.values().forEach(v => categories[v] = {});

        this.package_list.forEach(p => {
            if (p.pipeline_status !== 'success' || !p.has_results() || p.fail_count > 0) {
                this.packages_with_test_failures += 1;
            } else {
                this.packages_without_test_failures += 1;
            }

            if (p.fail_18_count > 0) this.packages_with_18_test_failures++;
            this.avg_score += p.get_score();
        });

        // const missing_py3 = this.package_list.filter(p => p.missing_py3).length;
        // dk.log("MISSING:PY3:", missing_py3);

        for (let c of categories_set) {
            this.package_list.forEach(p => {
                // dk.log(p.name, c, p.get_fail_count(c));
            });
        }
        // categories_set.values().forEach(c => {
        //     this.package_list.forEach(p => {
        //         dk.log(p.name, c, p.get_fail_count(c));
        //     });
        // });

        this.avg_score = Math.round(this.avg_score / this.package_count);
        this.coverage = covtot / this.pipeline_success_count;

        this.cmdline.statistics.package_count.html(`
            <span class="label">packages:</span> ${this.package_list.length}
        `);

        /*
        // console.info("LOCS:", this.locs);
        // this.cmdline.statistics.package_locs.html(`
        //     <span class="label">LOC:</span> ${humanize_int(this.locs)}
        // `);

        // this.cmdline.statistics.package_passing.html(`
        //     <span class="label">ok packages:</span> ${this.packages_without_test_failures}
        // `);

        // this.cmdline.statistics.package_failing.html(`
        //     <span class="label">test failures in:</span> ${this.packages_with_test_failures}
        // `);

        // this.cmdline.statistics.package_failing_18.html(`
        //     <span class="label">Django 1.8 failures in:</span> ${this.packages_with_18_test_failures}
        // `);

        // this.cmdline.statistics.missing_py35.html(`
        //     <span class="label">py3 missing:</span> ${this.package_list.filter(p => p.missing_py3).length}
        // `);
        */

        this.cmdline.statistics.avg_coverage.html(`
            <span class="label">avg. coverage (success):</span> ${Math.round(this.coverage * 100) / 100}%
        `);
        //             <span class="label">success:</span> ${this.pipeline_success_count}
        this.cmdline.statistics.pipeline_status.html(`
            <span class="label">pipeline failures:</span> ${this.pipeline_fail_count}
        `);
        this.cmdline.statistics.avg_score.html(`
            <span class="label">avg. score:</span> ${this.avg_score}
        `);

        // this.cmdline.statistics.py27.html(`
        //     <span class="label">py27 errors:</span> ${this.package_list.filter(p => p.get_fail_count('py27')).length}
        // `);

        current_tags.forEach(t => {
            this.cmdline.statistics[t].find('.value').text(
                this.package_list.filter(p => p.get_fail_count(t)).length
            );
        });
        // this.cmdline.statistics.py35.html(`
        //     <span class="label">py35 errors:</span> ${this.package_list.filter(p => p.get_fail_count('py35')).length}
        // `);

    }

    construct() {
        this.runs = [];
        this.summary = dk.$(this.summary_id);
        current_tags.forEach(t => {
            this.cmdline.sortbtns.append(`
                <button id="sortbtn-${t}" class="btn btn-default btn-xs ${t}-sort">
                    errors ${t}
                </button>
            `);
            this.cmdline.sortbtns[t] = this.cmdline.sortbtns.find(`#sortbtn-${t}`);
            this.cmdline.statistics.append(`
                <span id="stats-${t}" class="${t}" style="margin-right: 1ex;">
                    <span class="label">${t} errors:</span> <span class="value">0</span>
                </span>
            `);
            this.cmdline.statistics[t] = this.cmdline.statistics.find(`#stats-${t}`);
        });
        // this.package_data = this.package_data.slice(0, 5);
        this.add_packages(this.package_data);
    }

    sort_packages(which, btn, cmpfn) {
        // debugger;
        if (this._current_sort === which) {
            this._current_sort_direction *= -1;
        } else {
            this._current_sort_direction = 1;  // reset to ascending when changing sort term.
        }
        this._current_sort = which;
        this.cmdline.sortbtns.find('.active').removeClass('active');
        // create function that makes the sort stable.
        let _cmpfn = (a, b) => {
            let res = cmpfn(a, b);
            return (res === 0) ? a.name.localeCompare(b.name) : this._current_sort_direction * res;
        };
        this.package_list.sort(_cmpfn);
        this.package_list.forEach((item, i) => {
            if (i > 0) item.widget().insertAfter(this.package_list[i-1].widget());
        });
        btn.addClass('active');
        if (this._current_sort_direction === 1) {
            btn.addClass('asc');
            btn.removeClass('desc');
        } else {
            btn.addClass('desc');
            btn.removeClass('asc');
        }
    }

    draw() {
        this.cmdline.sortbtns.valuesort.trigger('click');
    }

    handlers() {
        // this.buildallbtn.on('click', () => this.build(this.package_list));
        // this.buildallbtn.on('dblclick', () => {this._found_packages = true; this.build(this.package_list);});
        //
        // this.buildchangedbtn.on('click', () => this.build(this.package_list.filter(x => x.changed())));
        // this.builderrbtn.on('click', () => this.build(this.package_list.filter(x => x.errcount())));
        // this.buildfastbtn.on('click', () => this.build(this.package_list.filter(x => x.duration() < 5)));

        const pkgpos = p => {
            const pos = ordering.indexOf(p.name);
            return pos === -1 ? 10000 : pos;
        };

        this.cmdline.sortbtns.namesort.on('click', () => {
            this.sort_packages('name', this.cmdline.sortbtns.namesort, (a, b) => a.name.localeCompare(b.name));
        });

        // tag error sorts
        current_tags.forEach(t => {
            this.cmdline.sortbtns[t].on('click', () => {
                this.sort_packages(t, this.cmdline.sortbtns[t], (a, b) => {
                    const acount = a.get_fail_count(t);
                    const bcount = b.get_fail_count(t);
                    if (acount !== bcount) return acount - bcount;
                    return b.get_score() - a.get_score();
                });
            });
        });

        this.cmdline.sortbtns.pipelinesort.on('click', () => {
            this.sort_packages('pipeline', this.cmdline.sortbtns.pipelinesort, (a, b) => {
                if (a.pipeline_status.localeCompare(b.pipeline_status) === 0) {                 // first sort by pipeline status
                    const posa = pkgpos(a);
                    const posb = pkgpos(b);
                    if (posa !== posb) return posa - posb;                                      // then dependency position
                    if (a.fail_count !== b.fail_count) return a.fail_count - b.fail_count;      // then failure count
                    return a.get_score() - b.get_score();                                       // then score
                } else {
                    return a.pipeline_status.localeCompare(b.pipeline_status);
                }
            });
        });

        this.cmdline.sortbtns.dependasort.on('click', () => {
            this.sort_packages('dependency', this.cmdline.sortbtns.dependasort, (a, b) => pkgpos(a) - pkgpos(b));
        });

        this.cmdline.sortbtns.timesort.on('click', () => {
            this.sort_packages('time', this.cmdline.sortbtns.timesort, (a, b) => a.duration - b.duration);
        });

        this.cmdline.sortbtns.covsort.on('click', () => {
            this.sort_packages('covpct', this.cmdline.sortbtns.covsort, (a, b) => a.coverage - b.coverage);
        });

        this.cmdline.sortbtns.locsort.on('click', () => {
            this.sort_packages('loc', this.cmdline.sortbtns.locsort, (a, b) => a.loc - b.loc);
        });

        this.cmdline.sortbtns.valuesort.on('click', () => {
            this.sort_packages('value', this.cmdline.sortbtns.valuesort, (a, b) => a.get_score() - b.get_score());
        });
        // this.cmdline.sortbtns.lintsort.on('click', () => {
        //     this.sort_packages('lintscore', this.cmdline.sortbtns.lintsort, (a, b) => a.lintscore() - b.lintscore());
        // });
        //
        // this.cmdline.sortbtns.lcsort.on('click', () => {
        //     this.sort_packages('linecount', this.cmdline.sortbtns.lcsort, (a, b) => a.linecount() - b.linecount());
        // });
        //
        // this.cmdline.sortbtns.changesort.on('click', () => {
        //     this.sort_packages('change', this.cmdline.sortbtns.changesort, (a, b) => b.changed() - a.changed());
        // });
    }
}
