/* external dk */

import {rgbToHsl, hex2rgb, rgb2hex, hslToRgb, hsl_wt_avg, text_color, text_color1} from "./colorscale";
import current_tags from './current-tags.json';

// const current_tags = _current_tags.tags;

const timestr = (secs) => {
    let res = secs;
    secs = Math.round(secs, 0);
    if (secs < 60) return `${secs}s`;
    let hours = Math.floor(secs / (60*60));
    secs -= hours * (60*60);
    let minutes = Math.floor(secs / 60);
    secs -= minutes * 60;

    if (hours) res = `${hours}h:${dk.format.twodigits(minutes)}m:${dk.format.twodigits(secs)}s`;
    else if (minutes) return `${minutes}m:${dk.format.twodigits(secs)}s`;
    else return `${secs}s`;
};

function allequal(vals) {
    if (vals.length === 0) return true;
    const first = vals[0];
    // console.log(vals, first, vals.reduce((a, b) => a === b ? a : false, first));
    return vals.reduce((a, b) => a === b ? a : false, first) === first;
}

export class Package extends dk.Widget {
    constructor(...args) {
        // console.log("CURRENT_TAGS:", current_tags);
        super({
            tag_priority: current_tags,
            // tag_priority: ['py35', 'dj18', 'dj19'],
            // tag_priority: ['py35', 'dj19', 'dj18'],
            rundata_template: `
                <template>
                    <tr>
                        <th data-name="tag"></th>
                        <td data-name="duration-box"></td>
                        <td data-name="coverage-box"></td>
                        <td data-name="failing-tests" class="red"></td>
                        <td data-name="passing-tests" class="green"></td>
                    </tr>
                </template>`,
            dom_template: `
                <template>
                    <div data-name="Package" class="">
                        <a data-name="avatar-link"><img data-name="avatar" alt="" src=""></a>

                        <div class="status" title="combined score">
                            <a data-name="score"></a>
                        </div>

                        <h2 data-name="project-name"></h2>

                        <div data-name="project-data">

                            <div data-name="LOC" class="value" data-values=""></div>
                            <div title="pipeline" data-name="pipeline"><dk-icon value="heartbeat"></dk-icon></div>
                            <div title="pylint" data-name="pylint-box" class="value" data-values=""></div>
                            <div title="Maintainability Index (MI)" data-name="MI" class="value" data-values=""></div>
                            <div title="Cyclomatic Complexity (CC)" data-name="CC" class="value" data-values=""></div>                                

                        </div>

                        <div class="run-data-box">
                            <table>
                                <tfoot>
                                    <tr style="line-height:1 !important">
                                        <td></td>
                                        <th><dk-icon title="duration" value="clock-o:fw"></dk-icon></th>
                                        <th><dk-icon title="coverage" value="umbrella:fw"></dk-icon></th>
                                        <th><dk-icon title="fail" value="times:fw"></dk-icon></th>
                                        <th><dk-icon title="pass" value="check:fw"></dk-icon></th>
                                    </tr>
                                </tfoot>
                                <tbody data-name="run-data">

                                </tbody>
                            </table>
                        </div>
                    </div>
            </template>`
        }, ...args);
    }

    get categories() {
        if (!(this.data && this.data.reports)) return [];
        const res = [];
        Object.keys(this.data.reports).forEach(t => t.split('-').forEach(st => res.push(st)));
        return res;
    }

    category_to_tag(category) {
        // dk.log("CAT2TAG:", category, this.tag_order);
        for (let tag of this.tag_order) {
            if (tag.split('-').includes(category)) return tag;
        }
        return null;
    }

    get tag_order() {
        if (!this._tag_order) {
            if (!(this.data && this.data.reports)) return ['', ''];
            const tags = Object.keys(this.data.reports);
            const tagval = t => t.split('-').map(part => this.tag_priority.includes(part)).reduce((a, b) => a+b, 0);
            this._tag_order = tags.sort((a, b) => tagval(a) - tagval(b));
        }
        return this._tag_order;
    }

    get primary_tag() { return this.tag_order[0]; }
    get secondary_tag() { return this.tag_order[1]; }

    get name() { return this.data.name; }

    get errored() { return this.primary_tag ? (this.data.reports[this.primary_tag].fail_count[0] > 0 ? 'failed' : 'success') : 'failed'; }
    get duration() { return this.primary_tag ? this.data.reports[this.primary_tag].elapsed[0] : 0; }
    get coverage() { return this.primary_tag ? this.data.reports[this.primary_tag].coverage[0] : 0; }
    // get value() { return Math.round((this.data.reports[this.primary_tag].value[0] || 0) * 10); }
    get values() { return this.primary_tag ? this.data.reports[this.primary_tag].value.map(v => Math.round((v||0)*10)) : 0; }
    get loc() {
        const val = this.first_value(this.primary_tag, 'loc');
        return val === "" ? 0 : val;
    }
    get pylint() {return Math.round(this.first_value(this.primary_tag, 'pylint') || 0, 2);}
    get mi() {return Math.round((this.first_value(this.primary_tag, 'mi') || 0), 2);}
    get cc() {return Math.round((this.first_value(this.primary_tag, 'cc') || 0), 2);}
    get value() { return Math.round((this.first_value(this.primary_tag, 'value') || 0) * 10); }

    get_fail_count(category) {
        const tag = this.category_to_tag(category);
        // if (!tag) return 10000;
        // dk.log("TAG:", category, tag);
        return this.first_value(tag, 'fail_count');
    }

    get fail_count() {
        return this.first_value(this.primary_tag, 'fail_count');
        // return this.tags.map(tag => this.first_value(tag, 'fail_count')).reduce((a, b) => a+b, 0);
    }

    get missing_py3() { return false; }

    get pass_count() {
        return this.first_value(this.primary_tag, 'pass_count');
        // return this.tags.map(tag => this.first_value(tag, 'pass_count')).reduce((a, b) => a+b, 0);
    }
    get avg_coverage() {
        const vals = this.tags.map(tag => this.first_value(tag, 'coverage'));
        const tot = vals.reduce((a, b) => a+b, 0);
        return Math.round(tot / vals.length);
    }

    get_score() {
        if (!this.has_results()) return 0;
        let res = this.value;
        // console.log(this.name, )
        if (this.fail_count > 0 && res > 500) res = 500;
        if (this.fail_count > 0) res -= 100;
        if (this.fail_count > 3) res -= 50;
        if (this.pass_count > 0) res += 50;
        res += this.mi;
        res += this.cc;
        res += this.pylint;
        res -= 100 - this.avg_coverage;
        if (this.pipeline_status === 'failed') res -= 150;
        // if (this.missing_py3) res -= 75;
        res = Math.round(res);

        if (res < 0) res = 0;
        if (res > 1000) res = 1000;
        return res;
    }

    get project_url() {
        if (this.data.pipeline) {
            return this.data.pipeline.project_url;
        }
        return '';
    }
    get pipeline_url() {
        if (this.data.pipeline) {
            return this.data.pipeline.pipeline_url;
        }
        return '';
    }
    get pipeline_status() {
        if (this.data.pipeline) {
            return this.data.pipeline.status;
        }
        return '';
    }
    get tags() { return this.data.reports ? Object.keys(this.data.reports) : []; }

    has_results() { return this.tags.length > 0; }

    get_data(tag, field) {
        if (!this.data.reports) return "";
        const reports = this.data.reports;
        const tag_data = reports[tag];
        if (!tag_data) return "";
        return tag_data[field];
    }

    first_value(tag, field) {
        const val = this.get_data(tag, field);
        return val && val.length >= 1 ? val[0] : "";
    }

    construct() {
        this.add_dom_template(this.rundata_template, 'package-rundata-template');
    }

    background_color() {
        const end = window.dark_theme ? '#378331' : '#40993a';
        const start = '#b7312e';
        const first = rgbToHsl(...hex2rgb(start));
        const last = rgbToHsl(...hex2rgb(end));
        return '#' + rgb2hex(...hslToRgb(...hsl_wt_avg(first, last, this.get_score(), 1000)));
    }

    draw() {
        this.project_name.text(this.name);
        this.avatar_link.attr('href', this.project_url);
        if (this.loc >= 1000) {
            this.LOC.text(Math.round(this.loc/1000, 2));
            this.LOC.addClass('kloc');
        } else {
            this.LOC.text(this.loc);
        }
        this.pylint_box.text(this.pylint);
        // if (this.missing_py3) this.missingpy3_box.text('py3');
        this.MI.text(this.mi);
        this.CC.text(this.cc);
        const score = this.get_score();
        // this.score.text(score === 1000 ? ':-)' : score);
        this.score.text(score);
        this.score.attr('href', this.pipeline_url);
        this.pipeline.addClass(this.pipeline_status);
        this.pipeline.attr('title', "pipeline: " + this.pipeline_status);
        this.Package.addClass(this.pipeline_status);

        const background_color = this.background_color();
        this.Package.css('background-color', background_color);
        const txt_color = text_color1(background_color);
        this.Package.css('color', txt_color);

        this.Package.addClass(txt_color === 'white' ? 'dark' : 'light');
        // const [h,s,l] = rgbToHsl(...hex2rgb(background_color));
        // this.Package.attr('hue', h);
        // this.Package.attr('sat', s);
        // this.Package.attr('lite', l);

        this.rows = [];
        this.tags.forEach(tag => {
            if (tag === 'py35-dj19') return;
            const row = {};
            this.append_dom_template('#package-rundata-template', this.run_data, row);
            row.tag.text(tag);
            row.duration_box.text(Math.round(this.first_value(tag, 'elapsed')));
            row.coverage_box.text(Math.round(this.first_value(tag, 'coverage')));

            const fail_count = this.first_value(tag, 'fail_count');
            row.failing_tests.text(fail_count);
            if (fail_count === 0) {
                row.failing_tests
                    .removeClass('red')
                    .addClass('green');
            }
            let pass_count = this.first_value(tag, 'pass_count');
            row.passing_tests.text(pass_count);
            if (!pass_count) {
                row.passing_tests
                    .removeClass('green')
                    .addClass('red');
            }

            this.rows.push(row);
        });

        // this.widget().addClass(this.errored);

        // this.coverage_text.text(this.coverage);
        // this.duration_value.text(timestr(this.duration));
        // this.coverage_link.attr('title', 'coverage');
        // this.duration_value.attr('title', 'duration');
        // this.score.text(this.value);
        //
        // if (!allequal(this.values)) {
        //     this.sparkline.html(`
        //         <embed src="sparkline.svg?${this.values.reverse().join(',')}" width="35" height="10">
        //     `);
        // } else {
        //     this.sparkline.html(`<dk-icon style="color:#666;font-size:15px;" value="ellipsis-h"></dk-icon>`);
        // }

        // this.avatar_link.attr('href', this.data.project.web_url);
        // this.avatar_link.avatar

        this.avatar
            .attr('src', `icons/${this.name}.png`);

        // this.avatar_link.avatar.attr('src', `https://norsktest.gitlab.io/gitlab/icons/${this.name}.png`);
    }
}
