
import {Package} from "./package";
import status_data from "../public/project-reports";
import {Packages} from "./packages";

const dkgl = {
    Package,
    Packages,
    status_data,
};

export default dkgl;
