/* external dk */

//
//try {
//    global
//} catch (e) {
//    var global = window;
//}

/**
 * Converts an RGB color value to HSL. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes r, g, and b are contained in the set [0, 255] and
 * returns h, s, and l in the set [0, 1].
 *
 * @param   r       The red color value
 * @param   g       The green color value
 * @param   b       The blue color value
 * @return  Array           The HSL representation
 */
export function rgbToHsl(r, g, b) {
    r /= 255, g /= 255, b /= 255;
    var max = Math.max(r, g, b), min = Math.min(r, g, b);
    var h, s, l = (max + min)/2;

    if (max === min) {
        h = s = 0; // achromatic
    } else {
        var d = max - min;
        s = l > 0.5? d/(2 - max - min): d/(max + min);
        switch (max) {
            case r:
                h = (g - b)/d + (g < b? 6: 0);
                break;
            case g:
                h = (b - r)/d + 2;
                break;
            case b:
                h = (r - g)/d + 4;
                break;
        }
        h /= 6;
    }

    return [h, s, l];
}

/**
 * Converts an HSL color value to RGB. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes h, s, and l are contained in the set [0, 1] and
 * returns r, g, and b in the set [0, 255].
 *
 * @param   h       The hue
 * @param   s       The saturation
 * @param   l       The lightness
 * @return  Array           The RGB representation
 */
export function hslToRgb(h, s, l) {
    let r, g, b;

    if (s === 0) {
        r = g = b = l; // achromatic
    } else {
        function hue2rgb(p, q, t) {
            if (t < 0) t += 1;
            if (t > 1) t -= 1;
            if (t < 1/6) return p + (q - p)*6*t;
            if (t < 1/2) return q;
            if (t < 2/3) return p + (q - p)*(2/3 - t)*6;
            return p;
        }

        var q = l < 0.5? l*(1 + s): l + s - l*s;
        var p = 2*l - q;
        r = hue2rgb(p, q, h + 1/3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1/3);
    }

    return [r*255, g*255, b*255];
}

/**
 * Converts an RGB color value to HSV. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSV_color_space.
 * Assumes r, g, and b are contained in the set [0, 255] and
 * returns h, s, and v in the set [0, 1].
 *
 * @param   r       The red color value
 * @param   g       The green color value
 * @param   b       The blue color value
 * @return  Array           The HSV representation
 */
export function rgbToHsv(r, g, b) {
    r = r/255, g = g/255, b = b/255;
    let max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h, s, v = max;

    let d = max - min;
    s = max === 0? 0: d/max;

    if (max === min) {
        h = 0; // achromatic
    } else {
        switch (max) {
            case r:
                h = (g - b)/d + (g < b? 6: 0);
                break;
            case g:
                h = (b - r)/d + 2;
                break;
            case b:
                h = (r - g)/d + 4;
                break;
        }
        h /= 6;
    }

    return [h, s, v];
}

/**
 * Converts an HSV color value to RGB. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSV_color_space.
 * Assumes h, s, and v are contained in the set [0, 1] and
 * returns r, g, and b in the set [0, 255].
 *
 * @param   h       The hue
 * @param   s       The saturation
 * @param   v       The value
 * @return  Array           The RGB representation
 */
export function hsvToRgb(h, s, v) {
    var r, g, b;

    var i = Math.floor(h*6);
    var f = h*6 - i;
    var p = v*(1 - s);
    var q = v*(1 - f*s);
    var t = v*(1 - (1 - f)*s);

    switch (i%6) {
        case 0:
            r = v, g = t, b = p;
            break;
        case 1:
            r = q, g = v, b = p;
            break;
        case 2:
            r = p, g = v, b = t;
            break;
        case 3:
            r = p, g = q, b = v;
            break;
        case 4:
            r = t, g = p, b = v;
            break;
        case 5:
            r = v, g = p, b = q;
            break;
    }

    return [r*255, g*255, b*255];
}


export function hex2rgb(h) {
    return h
        .replace(/^#/, '')
        .match(/.{2}/g)
        .map(v => parseInt(v, 16));
}


export function rgb2hex(r, g, b) {
    // b << 0 returns b as an integer
    return ((r << 16) + (g << 8) + (b << 0)).toString(16);
}


/**
 * From w3c (range 0..255)
 * @param r
 * @param g
 * @param b
 */
export function brightness(r, g, b) {
    return Math.floor((r * 299 + g * 587 + b * 114) / 1000);
}

/**
 * Greater than 125 is good.
 * @param a
 * @param b
 * @returns {*}
 */
export function brightnessdiff(a, b) {
    return Math.abs(brightness(...a) - brightness(...b));
}


export function text_color1(bghex) {
    const bdiff = brightnessdiff(hex2rgb(bghex), [0,0,0]);
    const wdiff = brightnessdiff(hex2rgb(bghex), [255, 255, 255]);
    // const wdiff = brightnessdiff(hex2rgb(bghex), [155, 155, 155]);
    // const wdiff = brightnessdiff(hex2rgb(bghex), hex2rgb(bghex));
    // console.log(bghex, 'white:', wdiff, 'black:', bdiff);
    // const t2 = text_color(bghex);
    // const res = bdiff > wdiff ? 'black' : 'white';
    // if (res !== t2) console.log('     diff:t1:', res, 't2:', t2);
    // debugger;
    return bdiff > wdiff ? 'black' : 'white';
}


export function linear_rgb_value(csrgb) {
    if (csrgb > 0.03928) {
        return ((csrgb + 0.055) / 1.055) ** 2.4;
    } else {
        return csrgb / 12.92;
    }
}

export function relative_luminance(hex_rgb) {
    const [r, g, b] = hex2rgb(hex_rgb).map(v => v/255);
    return (
        0.2126 * linear_rgb_value(r)
        + 0.7152 * linear_rgb_value(g)
        + 0.0722 * linear_rgb_value(b)
    );
}


export function contrast_ratio(a, b) {
    const rla = relative_luminance(a);
    const rlb = relative_luminance(b);
    const [L1, L2] = rla > rlb ? [rla, rlb] : [rlb, rla];
    return (L1 + 0.05) / (L2 + 0.05);
}


export function text_color(bghex) {
    const cr_white = contrast_ratio('#ffffff', bghex);
    const cr_black = contrast_ratio(bghex, '#000000');
    console.log('     ', bghex, 'white:', cr_white, 'black:', cr_black);
    // debugger;
    return cr_white > cr_black ? 'white' : 'black';
    // return l <= .5 ? 'white' : 'black';
    // console.log(bghex, Math.round(l, 3), l <= .5 ? 'white' : 'black');
    // return l <= .5 ? 'white' : 'black';
    // return l <= .501 ? 'white' : 'black';
}


export function wtavg(a, b, step, tot) {
    const bpct = step / (tot-1);
    const apct = 1 - bpct;
    return apct * a + bpct * b;
}

export function hsl_wt_avg(a, b, step, tot) {
    return [
        wtavg(a[0], b[0], step, tot),
        wtavg(a[1], b[1], step, tot),
        wtavg(a[2], b[2], step, tot)
    ];
}


export class ColorScale extends dk.Widget {
    constructor(...args) {
        super({
            cellwidth: 100,
            cellheight: 100,

            colorparams: {
                start: '#1c3d19',
                end: '#65bb60',
                legend: [],
                steps: 12
            },
        }, ...args);
    }

    _wtavg(a, b, step, tot) {
        const bpct = step / (tot-1);
        const apct = 1 - bpct;
        return apct * a + bpct * b;
    }

    _hsl_wt_avg(a, b, step, tot) {
        return [
            this._wtavg(a[0], b[0], step, tot),
            this._wtavg(a[1], b[1], step, tot),
            this._wtavg(a[2], b[2], step, tot)
        ];
    }

    getcolor(i) {
        return this.legendvals[i];
    }

    init() {
        this.legends = this.colorparams.legend.map(x => x);
        this.legendvals = [];
        this.colors = [];
        var start = rgbToHsl(...hex2rgb(this.colorparams.start));
        var end = rgbToHsl(...hex2rgb(this.colorparams.end));
        var steps = !!this.colorparams.steps ? this.colorparams.steps : this.colorparams.legend.length;
        if (this.colorparams.prepend) {
            this.colorparams.prepend.forEach(item => {
                this.colors.unshift(item.color);
                this.colorparams.legend.unshift(item.legend);
            });
        }
        for (let i=0; i<steps; i++) {
            let val = rgb2hex(...hslToRgb(...this._hsl_wt_avg(start, end, i, steps)));
            this.colors.push(val);
            this.legendvals.push(val);
        }
        if (this.colorparams.append) {
            this.colorparams.append.forEach(item => {
                this.colors.push(item.color);
                this.colorparams.legend.push(item.legend);
            });
        }
        //console.log('colors', this.colors);
    }

    construct() {
        this.colors.forEach((color, index) => {
            this.widget().append(
                $(`<div>${this.colorparams.legend[index] || color}</div>`).css({
                    float: 'left',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    fontSize: '85%',
                    backgroundColor: color,
                    color: text_color(color),
                    width: this.cellwidth,
                    height: this.cellheight
                })
            );
        });
    }
}
